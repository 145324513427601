<template>
  <div class="wrap" :class="isShowOther?'wrap-spb2':'wrap-spb'">
    <p style="text-align: center; font-size: 25px;font-weight:normal;">
      慈溪市自然资源和规划局审批表
    </p>
    <p style="float: left;font-size:14px; margin:0; padding:0;margin-left: 60%;margin-top:-12px;margin-bottom:5px;">案卷号&emsp;{{ form.KSAJH }}</p>
    <table
      width="550px"
      cellspacing="1"
      cellpadding="4"
      class="tabtop13"
      style="border: 1px solid #000000;margin:0 auto;font-size:14px;"
    >
      <tr>
        <td rowspan="1" style="width: 70px;text-align:center">申报单位</td>
        <td colspan="5">{{form.SBDW}}</td>
      </tr>
      <tr>
        <td rowspan="1" style="text-align:center">联系人</td>
        <td colspan="2" style="width: 100px;">{{form.LXR}}</td>        
        <td rowspan="1" style="width: 70px;text-align:center">联系电话</td>
        <td colspan="2" style="width: 70px;">{{form.LXDH}}</td>        
      </tr>
      <tr>
        <td rowspan="1" style="text-align:center">项目名称</td>
        <td colspan="5">{{form.XMMC}}</td>
      </tr>
      <tr>
        <td rowspan="1" style="text-align:center">建设地点</td>
        <td colspan="5">{{form.JSDD}}</td>
      </tr>
      <tr>
        <td rowspan="1" style="text-align:center">审批事项</td>
        <td colspan="5">{{form.SPSX}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center;">土地坐落</td>
        <td colspan="5">{{form.TDZL}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">土地使用证号</td>
        <td colspan="5">{{form.TDSYZH}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">土地面积</td>
        <td colspan="5">{{form.TDMJ}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">房产所有权证号</td>
        <td colspan="5">{{form.FCSYQZH}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">土地等级</td>
        <td colspan="5">{{form.TDDJ}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">地号</td>
        <td colspan="5">{{form.DH}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">功能用途</td>
        <td colspan="5">{{form.GNYT}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">房屋面积</td>
        <td colspan="5">{{form.FWMJ}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">拟变更功能、用途</td>
        <td colspan="5">{{form.NBGNYT}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">土地面积（新）</td>
        <td colspan="5">{{form.TDMJNEW}}</td>
      </tr>
      <tr v-if="isShowOther">
        <td rowspan="1" style="text-align:center">房屋面积（新）</td>
        <td colspan="5">{{form.FWMJNEW}}</td>
      </tr>
      <tr v-for="(item,index) in form.YJDATA" :key="index">
        <td rowspan="1"  colspan="6" :style="isShowOther?'height:10px;vertical-align: top;':'height:145px;vertical-align: top;'">
          <div style="height:100%;position:relative;">
          <span style="margin-top:8px;display:inline-block;">{{item.LABEL}}</span>
          <div style="width:550px;margin-top:-10px;margin-bottom:45px;word-wrap:break-word;">
            <p>&emsp;{{item.YJNR}}</p>
          </div>
          <div style="position:absolute;margin-left:40%;bottom:13px;width:60%;">
            <span style="display:inline-block;">签字</span>
            <span>
              <span v-if="item.YJRXM==null||item.YJRXM.length < 20 ">&emsp;{{item.YJRXM}}</span>
              <!-- <span v-if="item.YJRXM.length < 20&&item.YJRXM!=null ">{{item.YJRXM}}</span> -->
              <img :src="`data:image/png;base64,`+item.YJRXM" v-else style="height:40px;position:absolute;top:-13px;"  alt="" />
              </span>
            <span style="position:absolute;left:45%;">日期 {{getDate(item.YJRQ)}}</span>
          </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      nowTime: '',
    }
  },
  props: {
    form: {
      type: Object,
    },
    isShowOther:{
      type: Boolean,
    }
  },
  methods: {
    getDate(date) {
      var a = date;
      a= dayjs(date).format('YYYY-MM-DD');
      if(date == null||date == ''||date == undefined){
        return '';
      }else{
        return a;
      }
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.tabtop13 td {
  background-color: #ffffff;
  padding: 0 8px;
}

.wrap {
  width: 100%;
  margin: 0 auto;
  display: none;
}
.wrap table {
  /* font-size: 2em; */
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #000000;
}
.wrap table td {
  border: 1px solid #000000;
  height: 35px;
}

</style>
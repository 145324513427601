<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>审批表</span>
        <span>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="primary" @click="print">打印</el-button>
        </span>
      </div>
      </FloatTitBtn>
      <Ghjspbdy id="spb" :form="form2" :isShowOther="!isShowOther"></Ghjspbdy>
      <div class="edit-container">
        <el-form
          ref="form"
          :label-position="labelPosition"
          :model="form"
          :inline="false"
          label-width="130px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="部门:">
                <InputTree :bm="form.SPBM" @getInput="getInput" :stepId="'bm'" @blur="onSubmit('one')"></InputTree>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="科室案卷号:">
                <el-input v-model="form.KSAJH" @blur="onSubmit('one')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="申报单位:">
            <el-input v-model="form.SBDW" disabled></el-input>
          </el-form-item>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="联系人:">
                <el-input v-model="form.LXR" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话:">
                <el-input v-model="form.LXDH" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="项目名称:">
            <el-input v-model="form.XMMC" disabled></el-input>
          </el-form-item>
          <el-form-item label="建设地点:">
            <el-input v-model="form.JSDD" disabled></el-input>
          </el-form-item>
          <el-form-item label="审批事项:">
            <el-input v-model="form.SPSX" disabled></el-input>
          </el-form-item>
          <div v-if="!isShowOther">
          <el-form-item label="土地坐落:">
            <el-input @blur="onSubmit('one')" v-model="form2.TDZL" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="土地使用证号:">
            <el-input @blur="onSubmit('one')" v-model="form2.TDSYZH" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="土地面积:">
            <el-input @blur="onSubmit('one')" v-model="form2.TDMJ" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="房产所有权证号:">
            <el-input @blur="onSubmit('one')" v-model="form2.FCSYQZH" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="土地等级:">
            <el-input @blur="onSubmit('one')" v-model="form2.TDDJ" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="地号:">
            <el-input @blur="onSubmit('one')" v-model="form2.DH" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="功能用途:">
            <el-input @blur="onSubmit('one')" v-model="form2.GNYT" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="房屋面积:">
            <el-input @blur="onSubmit('one')" v-model="form2.FWMJ" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="拟变更功能、用途:">
            <el-input @blur="onSubmit('one')" v-model="form2.NBGNYT" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="土地面积新:">
            <el-input @blur="onSubmit('one')" v-model="form2.TDMJNEW" :disabled="isDisabled"></el-input>
          </el-form-item>
          <el-form-item label="房屋面积新:">
            <el-input @blur="onSubmit('one')" v-model="form2.FWMJNEW" :disabled="isDisabled"></el-input>
          </el-form-item>
          </div>
          <div v-for="(item, index) in form.YJDATA" :key="index">
            <el-form-item :label="item.LABEL" v-show="item.YJLX == yjNum">
              <el-input
                v-model="item.YJNR"
                type="textarea"
                :rows="item.YJLX == '1'? 8 : 4 "
                @blur="onSubmit('one')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="item.LABEL" v-show="item.YJLX != yjNum">
              <el-input
                v-model="item.YJNR"
                type="textarea"
                :rows="item.YJLX == '1'? 8 : 4 "
                disabled
              ></el-input>
            </el-form-item>
            <el-row class="wrap-qz" :gutter="24">
              <el-col :span="12">
                <el-form-item label="签字:">
                  <el-input v-model="item.YJRXM" disabled v-if="item.YJRXM.length < 20 "></el-input>
                  <img :src="`data:image/png;base64,`+item.YJRXM" v-else style="height:40px;"  alt="" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="日期:">
                  <el-date-picker
                    style="width: 100%"
                    v-model="item.YJRQ"
                    :clearable="false"
                    :disabled="item.YJLX != yjNum"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import InputTree from '@/components/Input/InputTree.vue';
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";
import Ghjspbdy from '@/views/flowinfo/ghjspbdy.vue';
export default {
  name:'spb',
  components: {
    InputTree,
    FloatTitBtn,
    Ghjspbdy,
  },
  data() {
    return {
      labelPosition: 'left',
      form: {
        Id: '',
        BJID: '',
        SPBM: '',
        KSAJH: '',
        SBDW: '',//
        LXR: '',
        LXDH: '',
        XMMC: '',
        JSDD: '',
        SPSX: '',//
      },
      YJDATA: [
          { YJLX: '0', BJSPID: '', BJID: '', LABEL: '经办人意见:', YJNR: '', YJRXM: '', YJRQ: '' },
          { YJLX: '1', BJSPID: '', BJID: '', LABEL: '初审意见:', YJNR: '', YJRXM: '', YJRQ: '' },
          { YJLX: '2', BJSPID: '', BJID: '', LABEL: '科长意见:', YJNR: '', YJRXM: '', YJRQ: '' },
          { YJLX: '3', BJSPID: '', BJID: '', LABEL: '分管领导意见:', YJNR: '', YJRXM: '', YJRQ: '' },
          { YJLX: '4', BJSPID: '', BJID: '', LABEL: '局长意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        ],
      YJDATA2:[
        { YJLX: '0', BJSPID: '', BJID: '', LABEL: '经办人意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        { YJLX: '5', BJSPID: '', BJID: '', LABEL: '行政审批科意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        { YJLX: '6', BJSPID: '', BJID: '', LABEL: '用地与建筑科意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        { YJLX: '7', BJSPID: '', BJID: '', LABEL: '法规科经办人意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        { YJLX: '8', BJSPID: '', BJID: '', LABEL: '法规科科长意见:', YJNR: '', YJRXM: '', YJRQ: '' },
        { YJLX: '3', BJSPID: '', BJID: '', LABEL: '分管领导意见:', YJNR: '', YJRXM: '', YJRQ: '' },
      ],
      form2: {
        // Id: '',
        BJID: '',
        TDZL: '',
        TDSYZH: '',
        TDMJ: '',
        FCSYQZH: '',
        TDDJ: '',
        DH: '',
        GNYT: '',
        FWMJ: '',
        NBGNYT: '',
        TDMJNEW: '',
        FWMJNEW: '',
      },
      yjList: [],
      yjNum: 0,
      isDisabled: true,
      btnShow:false,
      isShowOther:true,
      isWfjzrd:false,
    }
  },
  mounted() {
    let item = this.$route.query.yj ? this.$route.query.yj : '9'
    item.split(',')
    this.yjList = [...item]
    // this.yjList=[0,1]
    this.yjNum = this.yjList[this.yjList.length - 1]
    this.form.BJID = this.$route.query.instanceId ?? "";
    // console.log('获取办件Id:',this.form.BJID);
    this.fetch();
  },
  methods: {
    print() {
      print({
        printable: "spb",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    getInput(value) {
      this.form.SPBM = value
    },
    getSpsx() {
      let me = this;
      me.isWfjzrd = false;
      this.$ajax
        .get(me.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
        .then(function (response) {
          if (response.data.IsSuccess) {
            let obj = response.data.Data
            for (let key in obj) {
              if (key == me.form.SPSX) {
                let s=false;
                if(obj[key] == "临时改变房屋用途审批"){
                  me.isShowOther = false
                }else if(obj[key] == "违法建筑认定"){
                  me.form.YJDATA=me.YJDATA2;
                  me.isWfjzrd = true;
                  // s = true;
                }
                me.getFwytSpb(s)
                me.form.SPSX = obj[key]
                break;
              }
            }
            me.getSpyj()
          } else {
            console.log(response.data.errorMessage);
          }
        });
    },
    async getlahz() {
      let me = this;
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/Getlahz?Id=" +
            me.$route.query.instanceId
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              let res = response.data.Data;
              me.form.SBDW = res.JSDW
              me.form.XMMC = res.XMMC
              me.form.LXR = res.LXR
              me.form.LXDH = res.SJH
              me.form.JSDD = res.JSWZ
              me.form.SPSX = res.SBLX
              me.getSpsx()
            } else {
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    async fetch() {
      let me = this
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/getbjspxx?BJID=" +
            this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              // console.log('打印审批表数据：',response);
              let res = response.data.Data;
              me.form = res
              me.form.YJDATA = me.YJDATA;
              if(me.form.KSAJH==null||me.form.KSAJH==''){
                //  me.form.KSAJH = me.$store.state.AJH;
              }
              if(me.yjNum == 0 && me.form.SPBM == null || me.yjNum == 0 && me.form.SPBM == ''){
                me.form.SPBM = me.$store.state.userInfo.DepartmentName;
              }
              me.getlahz()
            } else {
              console.log('报错：', response);
              me.form = response.data.Data;
              console.log(response.data.ErrorMessage);
            }

          });
        if (this.form.JBR === this.$router.user.profile.sub && this.$route.query.taskId) {
          this.isDisabled = false;
          this.btnShow = true
        }else if(this.yjNum!=9){
           this.btnShow=true
        }
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    //临时改变房屋用途审批表
    async getFwytSpb(s) {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/getspxx?BJID=" +
            this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form2=response.data.Data
              this.form2.SBDW=this.form.SBDW
              this.form2.LXR=this.form.LXR
              this.form2.LXDH=this.form.LXDH
              this.form2.SPSX=this.form.SPSX
              this.form2.XMMC=this.form.XMMC
              this.form2.JSDD=this.form.JSDD
              this.form2.KSAJH=this.form.KSAJH
              if(response.data.Data.TDZL==undefined){
                this.form2.TDZL=''
              }
              this.form2.YJDATA=[...this.form.YJDATA]
              if(!s){
                this.form2.YJDATA.splice(0,1)
              }
            } else {
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    // 添加/修改审批意见
    async addSpyj() {
      // console.log('++++',this.form);
      for (let y = 0; y < this.form.YJDATA.length; y++) {
        this.form.YJDATA[y].BJSPID = this.form.Id
        this.form.YJDATA[y].BJID = this.form.BJID
      }
      let x = this.yjNum;
      let s = this.getspyjX(x);
      if(s!=undefined){
        x = s
      }else{
        x = 9
      }
      if (this.form.YJDATA[x] && this.form.YJDATA[x] != undefined) {
        if(this.$store.state.userInfo.Signature === null){
          this.form.YJDATA[x].YJRXM = this.$router.user.profile.name
        }else{
          this.form.YJDATA[x].YJRXM = this.$store.state.userInfo.Signature;
        }
        let time= this.form.YJDATA[x].YJRQ
        if(time.length!=undefined && time.substr(0,1)==2){
          this.form.YJDATA[x].YJRQ = time
        }else{
        time=time.toLocaleDateString();
        let d = time.split("/");
        if (d[1] < 10) {
            d[1] = '0' + d[1]
        }
        this.form.YJDATA[x].YJRQ = d.join('-')
        }
        // let me=this
        // console.log(this.form.YJDATA[x].YJRQ);
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/AddorUpdatespyj", this.form.YJDATA[x]
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              // console.log('保存成功',response,me.form.YJDATA[x]);
            } else {
              ElMessage({
                type: 'error',
                message: response.data.ErrorMessage,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
            ElMessage({
              type: 'error',
              message: error,
            })
          })
      }
    },
    getspyjX(x){
      for (let i = 0; i < this.form.YJDATA.length; i++) {
        if(this.form.YJDATA[i].YJLX==x){
          return i;
        }
      }
    },
    //获取审批意见
    async getSpyj() {
      let me = this
      await this.$ajax
        .get(
          this.$appConfig.apiUrls.ywxtApi +
          "/api/YW/getspyj?BJSPID=" + this.form.Id + "&BJID=" +
          this.form.BJID + "&YJLX=" + "100"
        )
        .then(function (response) {
          let x = me.yjNum
          let s=me.getspyjX(x)
          if(s!=undefined){
            x = s
          }else{
            x = 9
          }
          if (response.data.IsSuccess) {
            let res = response.data.Data
            for (let i = 0; i < me.form.YJDATA.length; i++) {
              for (let j = 0; j < res.length; j++) {
                if (me.form.YJDATA[i].YJLX === res[j].YJLX) {
                  me.form.YJDATA[i].BJSPID = res[j].BJSPID//
                  me.form.YJDATA[i].BJID = res[j].BJID//
                  me.form.YJDATA[i].YJNR = res[j].YJNR//意见内容
                  if(res[j].YJR == null){
                    me.form.YJDATA[i].YJRXM = res[j].YJRXM//签字
                  }else{
                    me.form.YJDATA[i].YJRXM = res[j].YJR//签字
                  }
                  me.form.YJDATA[i].YJRQ = res[j].YJRQ//时间
                }
              }
            }
            if(me.yjNum == 0 && me.form.YJDATA[0].YJNR.length < 1){
              me.form.YJDATA[x].YJNR = "材料齐全，同意受理。"
              me.onSubmit('one');
            }
            if (me.yjNum!=0 && x != 9) {
              if (me.form.YJDATA[x].YJRXM == '') {
                if(me.$store.state.userInfo.Signature === null){
                  me.form.YJDATA[x].YJRXM = me.$router.user.profile.name
                }else{
                  me.form.YJDATA[x].YJRXM = me.$store.state.userInfo.Signature;
                }
              }
              if(me.form.YJDATA[x].YJRQ == ''){
                me.form.YJDATA[x].YJRQ = new Date();
              }
            }
          } else {
            if (me.form.YJDATA[x] && me.form.YJDATA[x] != undefined) {
              if(me.$store.state.userInfo.Signature === null){
                me.form.YJDATA[x].YJRXM = me.$router.user.profile.name
              }else{
                me.form.YJDATA[x].YJRXM = me.$store.state.userInfo.Signature;
              }
              if(me.yjNum == 0){
                me.form.YJDATA[x].YJNR = "材料齐全，同意受理。"
                me.onSubmit('one');
              }
              me.form.YJDATA[x].YJRQ = new Date()
            }
            console.log('未获取到审批意见');
          }
        })
        .catch(function (error) {
          ElMessage({
            type: 'error',
            message: error,
          })
        })
    },
    async onSubmit(type) {
      let me = this
      me.form2.KSAJH=me.form.KSAJH
      if (me.checkform()) {
        try {
          await this.$ajax
            .post(
              this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatebjspxx",
              this.form
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.$ajax
                  .post(
                    me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatespxx?BJID=" + me.form.BJID,
                    me.form2
                  )
                  .then(function (res) {
                    if (res.data.IsSuccess) {
                      me.form.Id = response.data.Data
                      if(me.yjNum!=9){
                        me.addSpyj()
                      }
                      if(type !== 'one'){
                      ElMessage({
                        type: "success",
                        message: "保存成功!",
                      });
                      }
                    } else {
                      ElMessage({
                        type: "info",
                        message: "保存失败!",
                      });
                    }
                  });
              } else {
                ElMessage({
                  type: "info",
                  message: "保存失败!",
                });

              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    checkform() {
      let data = ['SPBM']
      let labelList = ['部门']
      let r = -1;
      for (let i = 0; i < data.length; i++) {
        let key = data[i]
        if (this.form[key] == '' || this.form[key] == undefined) {
          r = i;
          break;
        }
      }
      if (r == -1) {
        if (this.form.YJDATA) {
          let yjData = this.form.YJDATA
          let check = 0;
          let x = this.yjList[this.yjList.length - 1]
          if(this.isWfjzrd){
            let data = this.form.YJDATA.find((e)=>e.YJLX==x)
            if(data && data.YJNR.trim() === ''){
              ElMessage({
                type: 'info',
                message: '请输入意见',
              })
              return false;
            }
          }
          if (this.form.YJDATA[x] && !this.isWfjzrd) {
            if (yjData[x].YJNR.trim() === '' || yjData[x].YJNR === undefined) {
              check++;
            }
            if (check > 0) {
              ElMessage({
                type: 'info',
                message: '请输入意见',
              })
              return false;
            }
          }

          return true;
        }
        return true;
      } else {
        ElMessage({
          type: 'info',
          message: '请输入' + labelList[r],
        })
        return false;
      }
    },
  },
}
</script>
<style scoped>
/* .wrap-qz {
  margin-left: 50px;
} */
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
</style>
